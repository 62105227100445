import React from "react";
import g from "glamorous";
import Link from "gatsby-link";
import { FaPlus } from "react-icons/fa";

const Div = g.div({
  borderRadius: `0 0 4px 4px`,
  padding: `12px`,
  backgroundColor: `white`,
  color: `#1f1f1f`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  margin: `0 0 2em`,
  transition: `all 0.15s ease-in-out`
});

const Title = g.div({
  margin: `0 auto 1em`,
  letterSpacing: `0.05em`,
  fontSize: `1.2em`,
  paddingBottom: `3px`,
  color: `#49697e`,
  borderBottom: `2px solid #49697e`,
  fontWeight: `bold`
});

const Date = g.div({
  fontSize: `.7em`,
  letterSpacing: `0.05em`,
  fontWeight: `600`,
  color: `#49697e`,
  marginBottom: `-3px`
});

const NewsTitle = g.div({
  fontSize: `.95em`,
  lineHeight: `1.555em`,
  margin: `0 0 1.5em`,
  color: `#1f1f1f`,
  borderBotton: `none`,
  paddingBottom: `2px`,
  ":hover": {
    textDecoration: `underline`
  }
});

const More = g.div({
  color: `#49697e`,
  fontSize: `14px`,
  textAlign: `right`
});

export default ({ ...props }) => (
  <Div>
    <Title>Latest news</Title>

    <Date>10/25/2018</Date>
    <Link to="/en/news/which-treatment-to-eliminate-fat/">
      <NewsTitle>Which treatment to eliminate fat?</NewsTitle>
    </Link>

    <Date>10/10/2018</Date>
    <Link to="/en/before-after/rhinoplasty/">
      <NewsTitle>Before and after pictures of rhinoplasty treatments</NewsTitle>
    </Link>

    <Date>10/01/2018</Date>
    <Link to="/en/news/Diagnosing-age-skin-visia-complexion/">
      <NewsTitle>
        Diagnosing of the age of your skin with Visia Complexion  Analysis
      </NewsTitle>
    </Link>

    <Date>09/242018</Date>
    <Link to="/en/body-contouring/Liposuction-saddlebags/">
      <NewsTitle>Liposuction of the saddlebags</NewsTitle>
    </Link>

    <Date>06/06/2018</Date>
    <Link to="/en/skin/keybody-skin/">
      <NewsTitle>Keybody-Skin : Regenerate your skin</NewsTitle>
    </Link>

    <More>
      <Link to="/en/news/" css={{ color: `#49697e` }}>
        <FaPlus
          css={{
            fontSize: `1em`,
            padding: `0 .3em 0.13em`,
            transform: `translateY(3px)`
          }}
        />
        All news
      </Link>
    </More>
  </Div>
);
