import React from "react";
import { graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import ContactSidebar from "../components/ContactSidebar-en";
import AdresseSidebar from "../components/AdresseSidebar-en";
import AvantApresBlock from "../components/AvantApresBlock-en";
import KeybodyBlock from "../components/KeybodyBlock-en";
import VideoBlock from "../components/VideoBlock-en";
import NewsBlock from "../components/NewsBlock-en";
import Carrousel from "../components/Carrousel-en";
import Layout from "../components/layout";
import Bio from "../components/Bio-en";
import Menu from "../components/Menu-en";
import Footer from "../components/Footer-en";
import Social from "../components/Social-en";
import { Link } from "gatsby";
import { FaHome } from "react-icons/fa";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import _ from "lodash";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds our post data

  const { frontmatter, html } = markdownRemark;

  // filter current page fron other page array
  const autresPages = _.remove(allMarkdownRemark.edges, function (arg) {
    return arg.node.frontmatter.path !== frontmatter.path;
  });

  const Wrapper = g.div({
    maxWidth: `1000px`,
    margin: `0 auto`,
  });

  const Title = g.h1({
    fontSize: `33px`,
    color: `#3c4d68`,
    maxWidth: `700px`,
    margin: `0 auto .3em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `42px`,
    "@media(max-width: 767px)": {
      fontSize: `24px`,
      lineHeight: `32px`,
      textAlign: `center`,
      margin: `5px auto 10px`,
    },
  });

  const Notes = g.div({
    fontSize: `12px`,
    marginTop: `4em`,
    lineHeight: `17px`,
    marginBottom: `2em`,
  });

  css.global(".gatsby-resp-image-figcaption", {
    textAlign: "center",
    fontStyle: `italic`,
    fontSize: `.85em`,
    lineHeight: `1.45em`,
    marginTop: `.5em`,
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
    "@media(max-width: 767px)": {
      marginBottom: `17px !important`,
    },
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  const TranslationBlock = g.div({
    fontSize: `.9em`,
    margin: `2.5em 0`,
    lineHeight: `1.55em`,
    fontStyle: `italic`,
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>{frontmatter.metaTitle}</title>
          <meta name="description" content={frontmatter.metaDescription} />
          <html lang="en" />
          {frontmatter.pathFr !== "/" && (
            <link
              rel="alternate"
              hreflang="en"
              href={`https://www.dr-kron.com${frontmatter.pathFr}`}
            />
          )}
          <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Avant-Après",
              "item": "https://www.dr-kron.com/en/before-after/"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "${frontmatter.title}",
              "item": "https://www.dr-kron.com${frontmatter.path}"
            }]
        }
    `}</script>
        </Helmet>
        <Menu pathFr={frontmatter.pathFr} />

        <Wrapper>
          <Container
            css={{
              padding: `50px 15px 0 !important`,
              "@media(max-width: 767px)": {
                padding: `20px 15px 0 !important`,
              },
            }}
          >
            <Row>
              <Col span={{ xs: 12 / 12, lg: 69 / 100 }}>
                <article>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to="/en/" {...cssBreadcrumbLink} title="Homepage">
                        <FaHome
                          css={{
                            fontSize: `1.2em`,
                            paddingBottom: `.2em`,
                            transform: `translateY(5px)`,
                          }}
                        />
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to="/en/before-after/" {...cssBreadcrumbLink}>
                        Before & after
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>{frontmatter.title}</BreadcrumbItem>
                  </Breadcrumb>

                  <Title>{frontmatter.title}</Title>

                  <div
                    className="page-content-avant-apres"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                  <TranslationBlock>
                    <p>
                      <span
                        role="img"
                        aria-label="English flag - drapeau anglais"
                      >
                        🇬🇧
                      </span>
                      <b>About the translation:</b> Parts of the English version
                      of this website were translated using computer generated
                      translation. If you notice a significant error or if you
                      have any doubt regarding the medical information provided,{" "}
                      <Link to="/en/contact-us/">please send us a message</Link>
                      .
                    </p>
                  </TranslationBlock>
                  <Social path={frontmatter.path} title={frontmatter.title} />
                  <Notes>
                    <p>
                      "The description of the nature of the procedure and
                      techniques performed by the physician may result in the
                      posting of information sheets that will precede, accompany
                      or follow the information provided by the physician during
                      the consultation. Information can be illustrated with
                      diagrams. But the publication of even anonymous photos or
                      "before and after" iconography could lead to a confusion
                      between the obligation to inform and the obligation of
                      results. The National Council considers, moreover, that
                      this publication may include ad-type excesses for an
                      unsuspecting public. In the same way the individual
                      situation statement, under the appearance of "clinical
                      case" is formally to be proscribed. "
                    </p>

                    <p>
                      Source: Ordinal compliance charter applicable to the
                      websites of the doctors of{" "}
                      <a
                        href="https://www.conseil-national.medecin.fr/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Conseil National de l'Ordre des Médecin
                      </a>
                      &nbsp;(May 2010)
                    </p>

                    <p>
                      The before and after photographs presented by this site
                      are illustrations of the information provided on the
                      information sheets. As an illustration, these
                      before-and-after photos have informative and
                      non-advertising value: they highlight the result that can
                      be obtained depending on the anatomy of a patient as well
                      as any scars that may be induced by an intervention.
                      Moreover, the photographs do not commit Dr. Kron to
                      provide a specific result.
                    </p>

                    <p>
                      Dr Kron pointed out that the information provided to
                      patients on this site does not replace a medical
                      consultation. Anyone wishing to benefit from additional
                      information is invited to consult a qualified practitioner
                      in Plastic, Cosmetic and Reconstructive Surgery.
                    </p>
                  </Notes>
                  <Bio />
                </article>
              </Col>
              <Col span={{ xs: 12 / 12, lg: 31 / 100 }}>
                <aside>
                  <ContactSidebar about={frontmatter.about} />
                  <AdresseSidebar />
                  <AvantApresBlock />
                  <KeybodyBlock />
                  <VideoBlock />
                  <NewsBlock />
                </aside>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Carrousel other={autresPages} />
        <Footer />
      </g.Div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AvantApresPostByPathEn($path: String!, $tag: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        pathFr
        title
        tags
        about
        metaTitle
        metaDescription
      }
    }
    allMarkdownRemark(
      limit: 28
      filter: { frontmatter: { tags: { eq: $tag } } }
      sort: { fields: frontmatter___shortTitle, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            shortTitle
            excerpt
            path
            image {
              childImageSharp {
                fluid(maxWidth: 367) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
