import React, { Component } from "react";
import Slider from "react-slick";
import g from "glamorous";
import { css } from "glamor";
import Img from "gatsby-image";
import { Link } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = g.div({
  maxWidth: `1920px`,
  padding: `1em auto 2em auto`
});

const SliderWrapper = g.div({
  width: `95%`,
  margin: `0 auto`,
  "@media(max-width: 1000px)": {
    width: `93%`
  },
  "@media(max-width: 800px)": {
    width: `90%`
  },
  "@media(max-width: 480px)": {
    width: `88%`
  }
});

const H5 = g.h5({
  padding: `.65em .5em 0 .6em`,
  minHeight: `48px`,
  fontSize: `17px`,
  lineHeight: `24px`,
  fontWeight: `600`,
  color: `#3c4d68`
});

const Tile = g.div({
  border: `1px solid rgba(0,0,0,.04)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  outline: `none`,
  ":hover": {
    border: `1px solid rgba(73,105,126,0.18)`,
    boxShadow: `0 1px 10px rgba(0,0,0,.26)`,
    transition: `all 0.15s ease-in-out`
  },
  margin: `0 10px`,
  marginBottom: `2em`,
  minHeight: `390px`
});

const Text = g.p({
  padding: `.4em .5em 0 .8em`,
  marginBottom: `1.5em`,
  fontSize: `15px`,
  lineHeight: `23px`,
  textDecoration: `none`,
  color: `#1f1f1f`,
  "@media(max-width: 767px)": {
    padding: `.3em .7em 0 .8em`
  }
});

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Wrapper>
        <SliderWrapper>
          <h4
            css={{
              textTransform: `uppercase`,
              fontWeight: `600`,
              fontSize: `22px`,
              color: `#3c4d68`,
              marginBottom: `1em`,
              marginLeft: `.55em`,
              marginTop: `1em`,
              "@media(max-width: 767px)": {
                marginTop: `.8em`,
                marginBottom: `.5em`,
                marginLeft: `-23px`
              }
            }}
          >
            Other articles&nbsp;:
          </h4>
        </SliderWrapper>

        <SliderWrapper className="sliderwrapper">
          <Slider {...settings}>
            {this.props.other.map(item => (
              <div css={{ outline: `none` }}>
                <Tile>
                  <Link
                    to={item.node.frontmatter.path}
                    css={{
                      textDecoration: `none`
                    }}
                  >
                    <Img
                      fluid={item.node.frontmatter.image.childImageSharp.fluid}
                      css={{ borderRadius: `4px 4px 0 0` }}
                      alt={item.node.frontmatter.title}
                    />

                    <H5>{item.node.frontmatter.shortTitle}</H5>
                  </Link>
                  <Text>{item.node.frontmatter.excerpt}</Text>
                </Tile>
              </div>
            ))}
          </Slider>
        </SliderWrapper>
      </Wrapper>
    );
  }
}

css.global(".sliderwrapper .slick-prev", {
  top: `50% !important`,
  left: `-26px !important`,
  width: `30px !important`,
  height: `40px !important`,
  "@media(max-width: 767px)": {
    left: `40% !important`
  }
});

css.global(".sliderwrapper .slick-next", {
  top: `50% !important`,
  right: `-26px !important`,
  width: `30px !important`,
  height: `40px !important`,
  "@media(max-width: 767px)": {
    right: `40% !important`
  }
});
